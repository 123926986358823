#shop-card {
  font-family: "Poppins", sans-serif;
}

.img-container {
  position: relative;
}

.upper-image {
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s;
  opacity: 0;
  -webkit-transition: opacity 1s;
  -moz-transition: opacity 1s;
  -ms-transition: opacity 1s;
  -o-transition: opacity 1s;
}

.card-container:hover .upper-image {
  opacity: 1;
}

/* this is img zoom in style */
.img-container {
  overflow: hidden;
}

/* style badge */

.badge-container {
  position: absolute;
  top: 4%;
  left: 4%;
  right: auto;
}

.badge {
  background-color: #f79837;
  font-size: 14px;
  font-weight: 400;
  top: 28% !important;
  padding: 5px 8px;
}

/* img overly icon show content */

.overly-content-btn {
  opacity: 0;
  position: absolute;
  top: 64%;
  left: 30%;
  transition: opacity 1s;
}

.card-container:hover .overly-content-btn {
  opacity: 1;
}

.overly-content-icon {
  opacity: 0;
  position: absolute;
  top: 10%;
  left: 76%;
  transition: opacity 1s;
}
.card-container:hover .overly-content-icon {
  opacity: 1;
}
.overly-content-icon .badge {
  background-color: rgb(255, 255, 255);
  color: #f07a04;
}
.overly-content-icon .badge:hover {
  background-color: #f07a04;
  color: rgb(255, 255, 255);
}
.overly-content-icon .badge:hover .icon {
  background-color: #f07a04;
  color: aliceblue;
}
.icon-btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.overly-content-btn:hover {
  color: #f07a04;
}

/* card style */
.card {
  box-shadow: none !important;
}
