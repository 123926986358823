#and-shop-navbar {
  font-family: "Poppins", sans-serif;
}
.navbar-brand {
  font-size: 30px;
  padding: 0;
  font-weight: 900;
  color: #f79837 !important;
}

.navbar .nav-link {
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: 3rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #243549;
}

.navbar {
  background-color: #fef5ef !important;
  padding: 20px 0;
  position: relative;
}

.navbar-icon:hover {
  color: #f79837;
}
.navbar-icon {
  margin: 0 7px;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh) !important;
  overflow: visible;
}

/* navbar toggle button display show */
.navbar-toggler {
  display: block;
}

.navbar .btn {
  border: none;
  box-shadow: none;
}

.navbar-shop .badge {
  background-color: #f79837;
  font-size: 14px;
  font-weight: 700;
  top: 28% !important;
  padding: 5px 7px !important;
  border-radius: 100% !important;
  -webkit-border-radius: 100% !important;
  -moz-border-radius: 100% !important;
  -ms-border-radius: 100% !important;
  -o-border-radius: 100% !important;
}

/*shop dropdown nav menue style start*/

.shop {
  min-width: 32em !important;
}

.dropdown-menu[data-bs-popper] {
  top: 177%;
  left: 0%;
}

.navbar .nav-link {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* navbar customize */
.navbar {
  position: -webkit-sticky;
  position: sticky;
}

/* this media query use toggle navbar section */

@media only screen and (max-width: 375px) {
  .navbar-shop {
    display: none !important;
  }
}

@media only screen and (max-width: 576px) {
  .navbar-brand {
    font-size: 26px;
    padding: 0;
    font-weight: 900;
    color: #f79837 !important;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 80%;
    background-color: white;
    width: 100%;
    left: 0%;
  }
  /* navbar icon */
  .navbar-shop {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 40%;
  }
}

@media only screen and (min-width: 577px) and (max-width: 990px) {
  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    position: absolute;
    top: 80%;
    background-color: white;
    width: 100%;
    left: 0%;
  }
  /* navbar icon */
  .navbar-shop {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 40%;
  }
}
