#header-banner {
  width: 100%;
  height: 630px;
  font-family: "Poppins", sans-serif;
  overflow: hidden;
}
.header-banner-content {
  margin-top: 12%;
}
.header-banner-content p {
  line-height: 95%;
}
.header-banner-content .title {
  font-size: 12vw;
  font-weight: 100;
  padding-top: 3%;
  color: rgb(0, 0, 0);
}
.header-banner-content .title-two {
  font-size: 12vw;
  font-weight: bold;
  color: aliceblue;
}
.header-banner-content .save {
  font-size: 24px;
  font-weight: bold;
  color: aliceblue;
}

@media only screen and (max-width: 768px) {
  .header-banner-text {
    margin-left: 8px;
  }
}
