#follow-us {
  font-family: "Poppins", sans-serif;
}

.img-container {
  position: relative;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.img-container:hover .image {
  opacity: 0.5;
}

.img-container:hover .middle {
  opacity: 1;
}

.text {
  color: white;
  font-size: 16px;
}

.social-shere-icon {
  color: black;
  font-size: 30px;
}
