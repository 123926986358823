.form-responsive {
  width: 40%;
}

.card {
  /* box-shadow: 20px 20px 50px 10px #f79837 inset; */
  box-shadow: 10px 10px 50px grey;
}
@media only screen and (max-width: 576px) {
  .form-responsive {
    width: 100%;
  }
}
@media only screen and (min-width: 577px) and (max-width: 992px) {
  .form-responsive {
    width: 100%;
  }
}
